<template>
  <section id="teams-and-conditions">
    <div v-if="$i18n.locale === 'th'">
      <b-card-text class="text-small mt-2 ml-2 mr-2">
        <h5><strong>คำยินยอมให้เก็บรวบรวม ใช้ ประมวลผลและเปิดเผยข้อมูลส่วนบุคคล ของผู้รับบริการ</strong></h5>
        <p />
        <p>ด้วยพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 เป็นกฎหมายที่มีเจตนารมณ์ปกป้อง การล่วงละเมิดและคุ้มครองสิทธิความเป็นส่วนตัวของข้อมูลส่วนบุคคล ได้กําหนดหลักการให้การเก็บรวบรวม ใช้ ประมวลผล หรือเปิดเผยข้อมูลส่วนบุคคลจะต้องขอความยินยอมพร้อมทั้งแจ้งวัตถุประสงค์ให้เจ้าของข้อมูลส่วนบุคคลทราบโดยชัดแจ้ง</p>
        <p>ข้าพเจ้าได้รับการอธิบายจากศูนย์ศรีพัฒน์ฯ ถึงวัตถุประสงค์ในการให้คํายินยอมเก็บรวบรวม ใช้ ประมวลผล หรือเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า เพื่อประโยชน์ในการดูแลสุขภาพของข้าพเจ้าแล้ว โดยสามารถนําข้อมูลระดับบุคคลไปใช้ประโยชน์ในการบริการดูแลสุขภาพ อาทิการจัดเก็บข้อมูลการตรวจรักษา/การผ่าตัด/การใช้ยา/ตรวจรักษาทางรังสีและการทำหัตถการ ความเสี่ยงผลข้างเคียงและภาวะแทรกซ้อน ทั้งนี้รวมถึงการประมวลผลทางด้านการวิจัยและทางด้านการตลาด ไว้ภายใต้ข้อกำหนดและเงื่อนไข ดังต่อไปนี้</p>
        <ol>
          <li>
            <h6><strong>คำนิยาม</strong></h6>
          </li>
        </ol>
        <p />
        <p><strong>&ldquo;</strong><strong>ข้อมูลส่วนบุคคล&rdquo;</strong>&nbsp;หมายความว่า ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ อาทิ ชื่อ-นามสกุล วันเกิด สถานที่เกิด เชื้อชาติ สัญชาติ น้ำหนัก ส่วนสูง ข้อมูลตำแหน่งที่อยู่ เลขประจำตัวประชาชน, เลขหนังสือเดินทาง, เลขบัตรประกันสังคม, เลขใบอนุญาตขับขี่, ข้อมูลทางชีวมิติ (Bio-metric) ไม่ว่าจะเป็นรูปภาพใบหน้า ลายนิ้วมือ ฟิล์มเอ็กซ์เรย์ ข้อมูลสแกนม่านตาข้อมูลอัตลักษณ์เสียง ข้อมูลพันธุกรรม และหมายความรวมถึงข้อมูลด้านสุขภาพของบุคคล ข้อมูลอิเล็กทรอนิกส์ ระเบียนสุขภาพ</p>
        <p><strong>&ldquo;</strong><strong>ข้อมูลด้านสุขภาพของบุคคล&rdquo;</strong>&nbsp;หมายความว่า ข้อมูลหรือสิ่งใด ๆ ที่แสดงออกมาในรูปเอกสารแฟ้ม รายงาน หนังสือ แผนผัง แผนที่ ภาพวาด ภาพถ่าย ฟิล์ม การบันทึกภาพหรือเสียงการบันทึกโดยเครื่องมือทางอิเล็กทรอนิกส์ หรือวิธีอื่นใดที่ทําให้สิ่งที่บันทึกไว้ปรากฏขึ้นในเรื่องที่เกี่ยวกับสุขภาพของบุคคลที่สามารถระบุตัวบุคคลได้และให้รวมถึงข้อมูลอื่น ๆ</p>
        <p><strong>&ldquo;</strong><strong>ข้อมูลอิเล็กทรอนิกส์&rdquo;</strong>&nbsp;หมายความว่า ข้อมูลด้านสุขภาพของบุคคลที่เป็นเอกสารหรือข้อความในรูปแบบอิเล็กทรอนิกส์</p>
        <p><strong>&ldquo;</strong><strong>ระเบียนสุขภาพ&rdquo;</strong>&nbsp;หมายความว่า ทะเบียนหรือรายการ ข้อมูลด้านสุขภาพของบุคคลที่กระทรวงสาธารณสุข หน่วยงานทั้งภาครัฐและเอกชน นํามาเก็บ จัดการ ใช้และเปิดเผยเพื่อประโยชน์ของเจ้าของข้อมูล</p>
        <p><strong>&ldquo;</strong><strong>เจ้าของข้อมูล&rdquo;</strong>&nbsp;หมายความว่า บุคคลผู้เป็นเจ้าของข้อมูลด้านสุขภาพ</p>
        <p><strong>&ldquo;</strong><strong>ผู้ควบคุมข้อมูล&rdquo;</strong>&nbsp;หมายความว่า ศูนย์ศรีพัฒน์ คณะแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่</p>
        <p><strong>&ldquo;</strong><strong>ผู้รับข้อมูล&rdquo;</strong>&nbsp;หมายความว่า บุคคลหรือนิติบุคคลที่ได้รับข้อมูลด้านสุขภาพของบุคคลจากผู้ควบคุมข้อมูลและนําข้อมูลนั้นไปใช้เพื่อประโยชน์ของเจ้าของข้อมูล</p>
        <ol start="2">
          <li>
            <h6><strong>ระยะเวลาในการเก็บรวบรวมข้อมูลส่วนบุคคล</strong></h6>
          </li>
        </ol>
        <p />
        <p>ศูนย์ศรีพัฒน์ฯ จะเก็บรักษาข้อมูลส่วนบุคคลของผู้รับบริการไว้เป็นระยะเวลาเท่าที่จำเป็นเพื่อการดำเนินการตามวัตถุประสงค์ให้เสร็จสิ้น เว้นแต่กรณีที่กฎหมายกำหนดหรืออนุญาตให้เก็บรักษาข้อมูลไว้นานกว่า ตามอายุความที่กฎหมายกำหนดสำหรับเรื่องที่เกี่ยวข้อง เพื่อการดำเนินคดี หรือเพื่อการตรวจสอบของหน่วยงานที่กำกับดูแล ทั้งนี้หากผู้รับบริการไม่กลับมาใช้บริการรักษาพยาบาล ณ ศูนย์ศรีพัฒน์ฯ นานกว่า 5 ปี ทางศูนย์ศรีพัฒน์ฯ จะดำเนินการทำลายข้อมูลส่วนบุคคลของท่านตามกฎหมายที่เกี่ยวข้องต่อไป</p>
        <ol start="3">
          <li>
            <h6><strong>การเปิดเผยข้อมูลส่วนบุคคล</strong></h6>
          </li>
        </ol>
        <blockquote>
          <p />
          <p>3.1 ศูนย์ศรีพัฒน์ฯ จะไม่เปิดเผยข้อมูลส่วนบุคคลต่อบุคคลใดโดยไม่ได้รับความยินยอมจากผู้รับบริการก่อน อย่างไรก็ดี ผู้รับบริการรับทราบและยินยอมว่า เพื่อประสิทธิภาพในการให้บริการหรือเพื่อปฏิบัติตามกฎหมาย ศูนย์ศรีพัฒน์ฯ อาจเปิดเผยข้อมูลส่วนบุคคลของผู้รับบริการให้กับหน่วยงานหรือเจ้าหน้าที่ของรัฐที่ใช้อำนาจหน้าที่ตามกฎหมาย</p>
          <p>3.2 หากผู้รับบริการเชื่อว่าบุคคลที่ศูนย์ศรีพัฒน์ฯ เปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการตามข้างต้น ได้มีการนำข้อมูลส่วนบุคคลของผู้ใช้บริการไปใช้เพื่อวัตถุประสงค์อื่นนอกเหนือจากขอบเขตที่ศูนย์ศรีพัฒน์ฯ ได้กำหนดไว้ ผู้รับบริการสามารถแจ้งศูนย์ศรีพัฒน์ฯ เพื่อดำเนินการในส่วนเกี่ยวข้องต่อไป</p>
          <p>3.3 ศูนย์ศรีพัฒน์ฯ อาจต้องเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการ เพื่อปฏิบัติตามกฎหมาย เช่น การเปิดเผยข้อมูลต่อหน่วยงานราชการ หน่วยงานภาครัฐ หน่วยงานที่มีหน้าที่กำกับดูแลการให้บริการ หรือหน่วยงานกำกับดูแลผู้ใช้บริการ รวมถึงในกรณีที่มีการร้องขอให้เปิดเผยข้อมูลโดยอาศัยอำนาจตามกฎหมาย รวมถึงในกรณีที่มีความจำเป็นตามสมควรในการบังคับใช้ข้อตกลงและเงื่อนไขการใช้บริการของศูนย์ศรีพัฒน์ฯ ตลอดจนการเปิดเผยข้อมูลในกรณีที่มีการปรับโครงสร้างองค์กร</p>
          <p>3.4 ในกรณีที่ผู้รับบริการได้จัดทำประกันต่างๆ กับบุคคลภายนอกไว้ก่อนหรือขณะลงนามในหนังสือให้ความยินยอมฉบับนี้ หากมีบริษัทประกันและหรือตัวแทนของบริษัทประกันมีความประสงค์ที่จะขอข้อมูลและหรือสำเนาข้อมูลสุขภาพของผู้รับบริการเพื่อวัตถุประสงค์ตามที่ผู้รับบริการได้ให้ไว้แก่บริษัทประกันและหรือตัวแทนของบริษัทประกันไว้ก่อนแล้ว ผู้รับบริการตกลงและยินยอมให้ศูนย์ศรีพัฒน์ฯเปิดเผยและสำเนาข้อมูลดังกล่าวให้แก่บริษัทประกันและหรือตัวแทนของบริษัทประกันได้ หากเกิดความเสียหายไม่ว่าส่วนใดหนึ่งผู้รับบริการตกลงและยินยอมที่จะไม่เรียกร้องหรือว่ากล่าวเอากับศูนย์ศรีพัฒน์ฯ และให้ถือว่าศูนย์ศรีพัฒน์ฯ ได้ปฏิบัติหน้าที่ในการเก็บ รวบรวมและรักษาข้อมูลของผู้รับบริการอย่างเต็มที่แล้ว</p>
        </blockquote>
        <ol start="4">
          <li>
            <h6><strong>การประมวลผล</strong></h6>
          </li>
        </ol>
        <p />
        <p>ศูนย์ศรีพัฒน์ ฯ อาจมีความจำเป็นในการนำข้อมูลของท่านไปประมวลผลทางด้านการวิจัยในส่วนที่เกี่ยวข้องกับการรักษา การเก็บสถิติ หรือด้านอื่นๆ ที่เกี่ยวข้อง และทางด้านการตลาดเพื่อเพิ่มประสิทธิภาพในการให้บริการอย่างเต็มที่</p>
        <ol start="5">
          <li>
            <h6><strong>สิทธิของเจ้าของข้อมูลส่วนบุคคล</strong></h6>
          </li>
        </ol>
        <blockquote>
          <p />
          <p>5.1 การแก้ไขหรือลบข้อมูลส่วนบุคคล ผู้รับบริการสามารถแก้ไขหรือลบข้อมูลส่วนบุคคลของผู้รับบริการเท่านั้นแต่ไม่รวมถึงข้อมูลด้านสุขภาพของบุคคลข้อมูลอิเล็กทรอนิกส์ ระเบียนสุขภาพ โดยติดต่อกับศูนย์ศรีพัฒน์ฯ ทาง Call center, โทร: 0-5393-6900-1 หรือ แก้ไขข้อมูลของท่านผ่านระบบสมาชิกของศูนย์ศรีพัฒน์ฯ เท่านั้น ทั้งนี้ การลบข้อมูลอาจทำให้ผู้ใช้บริการไม่สามารถรับบริการจากศูนย์ศรีพัฒน์ฯ ได้ หรืออาจทำให้การให้บริการไม่มีประสิทธิภาพเท่าที่ควร</p>
          <p>5.2 ในกรณีที่มีการร้องขอให้ลบข้อมูลส่วนบุคคลของผู้ใช้บริการจากระบบหรือฐานข้อมูลผู้รับบริการของศูนย์ศรีพัฒน์ฯ นั้นศูนย์ศรีพัฒน์ฯ จะพยายามอย่างเต็มที่ตามความสามารถของระบบงานที่เกี่ยวข้องเพื่ออำนวยความสะดวกและดำเนินการตามคำร้องขอของผู้ใช้บริการ เว้นแต่จะปรากฏข้อเท็จจริงว่า การดำเนินการตามคำร้องขอนั้นจะเสี่ยงต่อการละเมิดนโยบายความเป็นส่วนตัวของผู้ใช้บริการรายอื่น หรือเป็นการขัดต่อกฎหมาย หรือมีความจำเป็นที่จะต้องเก็บข้อมูลไว้เพื่อเป็นพยานหลักฐานในการตรวจสอบหรือดำเนินคดีตามกฎหมาย หรือเพื่อปฏิบัติตามหน้าที่ตามกฎหมายที่ใช้บังคับ หรือเพื่อเป็นไปตามนโยบายความปลอดภัยของระบบ หรือกรณีที่เป็นการพ้นวิสัยในทางปฏิบัติตามคำร้องขอของผู้ใช้บริการ</p>
        </blockquote>
        <ol start="6">
          <li>
            <h6><strong>ผลการเพิกถอนความยินยอม</strong></h6>
          </li>
        </ol>
        <blockquote>
          <p />
          <p>6.1 ผู้รับบริการผู้เป็นเจ้าของข้อมูลอาจเพิกถอนความยินยอมให้เปิดเผยข้อมูลส่วนบุคคลดังกล่าวข้างต้น โดยแจ้งขอเพิกถอนความยินยอมเป็นลายลักษณ์อักษรพร้อมเหตุผลให้แก่ศูนย์ศรีพัฒน์ฯ ทราบ</p>
          <p>6.2 การเพิกถอนความยินยอมของผู้ใช้บริการไม่มีผลลบล้างความยินยอมและผลแห่งความยินยอม ซึ่งได้กระทําไปแล้วก่อนหน้านั้น โดยการเพิกถอนความยินยอมต้องมีหลักฐานเป็นลายลักษณ์อักษร</p>
        </blockquote>
      </b-card-text>
    </div>
    <div v-else>
      <b-card-text class="text-small mt-2 ml-2 mr-2">
        <h5><strong>Consent for the collection, use, processing, and disclosure of service recipients' personal data</strong></h5>
        <p />
        <p>The Personal Data Protection Act B.E. 2562, a law intended to protect, abuse, and defend the right to privacy of personal data, provides that consent must be obtained prior to the collection, use, processing, or dissemination of such data. Consent and the purpose of contacting the owner of personal data must be made clear.</p>
        <p>Sriphat Medical Center has notified me of the rationale for allowing permission to collect, use, process, or disclose my personal data for the benefit of my health care, and I am permitted to use personal data to provide services. Subject to the following conditions and limits, health care, including data collection, treatment/surgery/drug use/radiotherapy risks, side effects, and consequences, as well as marketing and research processing.</p>
        <ol>
          <li>
            <h6><strong>Definition</strong></h6>
          </li>
        </ol>
        <p />
        <p><strong>&ldquo;</strong><strong>Personal data&rdquo;</strong> refers to information that may be used directly or indirectly to identify an individual, excluding information about deceased persons, such as name, surname, date and place of birth, race, nationality, weight, and height. Location data, address, identification number, passport number, social security number, driver's license number, biometric information (Bio-metric) including picture, face, fingerprint, film X-ray data, iris scan data, and voice identification data. In additional, it contains incorporates genetic and individual health data Electronic medical records.</p>
        <p><strong>&ldquo;</strong><strong>Health information of a person&rdquo;</strong> refers to any information provided in the form of an electronic document, file, report, book, plan, map, drawing, image, film, video, or audio recording, or by any other recording method, that relates to the health of an individually identifiable person and contains additional information.</p>
        <p><strong>&ldquo;</strong><strong>Electronic data&ldquo;</strong> refers to a person's health information in the form of an electronic document or text.</p>
        <p><strong>&ldquo;</strong><strong>Health record&ldquo;</strong> refers to a register or list of a person's health information that public and private Ministry of Public Health organizations gather, maintain, utilize, and disseminate for the benefit of the data owner.</p>
        <p><strong>&ldquo;</strong><strong>Owner's Data&ldquo;</strong> refers to an individual's health information.</p>
        <p><strong>&ldquo;</strong><strong>Data Controller&ldquo;</strong> refers to Sriphat Medical Center, Faculty of Medicine, Chiangmai University.</p>
        <p><strong>&ldquo;</strong><strong>Data receiver&ldquo;</strong> is a person or legal entity that receives and uses personal health data from a data controller for the benefit of the data owner.</p>
        <ol start="2">
          <li>
            <h6><strong>Period of Personal data collection</strong></h6>
          </li>
        </ol>
        <p />
        <p>Unless the law requires or authorizes a longer retention period for prosecution or for the inspection of regulatory agencies, Sriphat Medical Center will only retain the personal data of the service recipient for as long as necessary to fulfil the purpose. If the service recipient does not return to Sriphat Medical Center within five years to get medical services, Sriphat Medical Center will delete the individual's personal data and stay compliant with the law.</p>
        <ol start="3">
          <li>
            <h6><strong>Disclose of Personal data</strong></h6>
          </li>
        </ol>
        <blockquote>
          <p />
          <p>3.1 Sriphat Medical Center will not disclose any personal data without the consent of the service receiver. However, the service user acknowledges and agrees that the Sriphat Medical Center may disclose information in order to improve the service's efficacy or to comply with the law. Disclosure of the recipient's personal data to a government agency or official with legal authority</p>
          <p>3.2 If the recipient of the service believes that the person to whom Sriphat Medical Center disclosed the user's personal data as described above has used the user's personal data for purposes outside the scope established by Sriphat Medical Center, the recipient of the service may contact Sriphat Medical Center. The service is able to tell Sriphat Medical Center to continue with the applicable portion.</p>
          <p>3.3 Sriphat Medical Center may be required to reveal personal data of users in order to comply with the law, including releasing information to government agencies, government agencies, agencies responsible for overseeing the supply of services, or agencies that monitor service users. In cases where there is a request to disclose information pursuant to the law, including cases where it is reasonably necessary to enforce the terms and conditions of service of Sriphat Medical Center, as well as disclosure of information in cases involving organizational restructuring, the requested information will be disclosed.</p>
          <p>3.4 If the service recipient has made various insurance arrangements with third parties prior to or during the signing of this consent letter, and if the insurance company and/or representative of the insurance company wishes to request information and/or a copy of The recipient of the service's health information for the purpose provided by the recipient to the insurance company and/or representative of the insurance company, and the recipient agrees and consents, then the insurance</p>
          <p>company and/or representative of the insurance company may do so. Indeed, the service recipient agrees to hold Sriphat Medical Center blameless and to assume that Sriphat Medical Center has met its commitment to collect and maintain the service recipient's information if any damage occurs.</p>
        </blockquote>
        <ol start="4">
          <li>
            <h6><strong>Processing of personal data</strong></h6>
          </li>
        </ol>
        <p />
        <p>Sriphat Medical Center may need to handle your data for treatment-related, statistical, or other research purposes. linked and in marketing to increase service effectiveness</p>
        <ol start="5">
          <li>
            <h6><strong>Right of the subject of personal data</strong></h6>
          </li>
        </ol>
        <blockquote>
          <p />
          <p>5.1 Correction or deletion of personal data, the recipient is only permitted to edit or delete his or her personal data, which does not include his or her electronic health records. medical record by contacting Sriphat Medical Center through Call centre, telephone number 0-5393-6900-1, or by changing your details using the Sriphat Medical Center membership system. The erasure of information may prevent users from gaining access to Sriphat Medical Center or reduce the service's effectiveness.</p>
          <p>5.2 In the event of a request to delete a user's personal data from Sriphat Medical Center's system or customer database, Sriphat Medical Center will make all reasonable efforts, subject to the capability of the relevant system, to facilitate and act upon the user's request, unless it appears that processing the request may violate the privacy policies of other users or violate the law. Alternately, the material must be retained for use as evidence in an inquiry or judicial proceeding. or to fulfil statutory responsibilities or to comply with the system's security policy, or if the service user's request is impractical.</p>
        </blockquote>
        <ol start="6">
          <li>
            <h6><strong>Effect of withdrawing consent</strong></h6>
          </li>
        </ol>
        <blockquote>
          <p />
          <p>6.1 The data owner of the receiving service may withdraw permission to disclose the aforementioned personal data by informing Sriphat Medical Center in writing of the withdrawal of consent together with the reasons for the withdrawal.</p>
          <p>6.2 The withdrawal of permission by the user does not invalidate the consent, and the impact of the consent that has already been granted must be documented in writing.</p>
        </blockquote>
      </b-card-text>
    </div>
    <b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-center mb-1"
      >
        <b-form-group>
          <b-form-radio-group
            v-model="selected"
            button-variant="primary"
            :options="$i18n.locale === 'th' ? options : optionsENG"
            class="demo-inline-spacing"
            name="radio-inline"
          />
        </b-form-group>
        <!-- <b-form-checkbox
          v-model="selected"
          value="A"
          class="custom-control-primary"
        >
          ยินยอม
        </b-form-checkbox> -->
      </b-col>
      <b-col
        class="d-flex justify-content-center"
      >
        <b-button
          v-if="selected !== ''"
          variant="primary"
          block
          @click="consented(selected)"
        >
          {{ t('Button Save') }}
        </b-button>
        <b-button
          v-else
          variant="primary"
          block
          disabled
        >
          {{ t('Button Save') }}
        </b-button>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      selected: '',
      options: [
        { text: 'ยินยอม', value: true },
        { text: 'ไม่ยินยอม', value: false },
      ],
      optionsENG: [
        { text: 'Accept', value: true },
        { text: 'Decline', value: false },
      ],
    }
  },
  methods: {
    consented(selected) {
      this.$emit('consent', selected)
    },
  },
}
</script>

<style>
  p + p {
    text-indent: 2em;
  }
</style>
